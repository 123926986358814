import styled from "styled-components";

export const Title = styled.h3.attrs({
  className: `${(props) => props.className}`,
})`
  color: #142c1f;
  /* font-size: 2.3rem;
  font-weight: 500; */
`;

export const Wrap = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 100px;

  &.NutritionCoachWrap {
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 991.99px) {
    grid-template-columns: 1fr;
    gap: 50px;
  }
`;


export const VisualContent = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  img {
    width: 100%;
    height: auto;
    border-radius: 30px;
  }

  @media (max-width: 991.99px) {
    max-width: 500px;
    width: 100%;
    height: 500px;
    margin-inline: auto;
    order: 1;
  }

  @media (max-width: 767.99px) {
    height: 400px;
  }
`;

export const Image = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  position: relative;
  height: 100%;

  .shape,
  .img {
    position: absolute;
    top: 0;
    left: 0;
    min-height: 500px;
  }

  .shape {
    z-index: 12;
    width: 100%;
    height: 100%;
    min-height: 510px;
  }

  .img {
    width: 99%;
    height: 99%;
    margin: 0.5% auto 0;
    display: block;
    object-fit: cover;
    object-position: top;
  }

  @media (max-width: 600px) {
    .shape,
    .img {
      min-height: auto;
    }

    .shape {
      min-height: auto;
    }
  }
  @media (max-width: 500px) {
    .shape,
    .img {
      max-height: 500px;
    }

    .shape {
      max-height: 510px;
    }
  }
  @media (max-width: 400px) {
    .shape,
    .img {
      max-height: 400px;
    }

    .shape {
      max-height: 410px;
    }
  }
  @media (max-width: 300px) {
    .shape,
    .img {
      max-height: 300px;
    }

    .shape {
      max-height: 310px;
    }
  }
`;

export const TextContent = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  img {
    width: 100%;
  }

  @media (max-width: 991.99px) {
    order: 2;
  }
`;

export const PlanBoxIcons = styled.ul.attrs({
  className: `${(props) => props.className}`,
})`
  list-style: none;
  padding-left: 0;
  margin-top: 30px;
  li {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
  }
  &.procedure {
    li {
      align-items: start;
    }
  }
`;

export const PlanBoxIconBox = styled.span.attrs({
  className: `${(props) => props.className}`,
})`
  width: 40px;
  height: 40px;
  border-radius: 50%;

  img {
    width: 40px;
    height: 40px;
  }
`;

export const PlanBoxInformation = styled.span.attrs({
  className: `${(props) => props.className}`,
})`
  color: #44554c;
  /* font-size: 1rem;
  font-weight: 400; */
  width: calc(100% - 40px);
  padding-left: 20px;

  &.ProcedureTop {
    /* font-size: 1.15rem !important; */
    padding-top: 10px;
  }
  &.StandatProgramText {
    /* font-size: 1.15rem !important; */
  }
`;
