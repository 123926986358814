import React from "react"
import { useSelector } from "react-redux"
import { getS3ImageUrl } from "configs/utils"

import * as C from "./newAttitude.styled"

import Chip from "components/general/formComponents/chip/Chip"
// Images
// import Coach from "assets/images/Page2/Foodiary-Coaching-Bild2.svg"
import Coach from "assets/images/Page3/Foodiary-Coaching-start.svg";
import GreenTick from "assets/images/myCoaching/Icon-check.svg"
import ShapeOutline from "assets/images/pngs/shape-outline11px.png"

const NewAttitude = () => {
  const coach = useSelector((state) => state.coach.coach)

  const imgUrl = coach?.cw_fm_picture_2
    ? getS3ImageUrl(coach?.cw_fm_picture_2)
    : Coach

  return (
    <C.Wrap>
      <C.VisualContent>

      <C.Image>
          {coach?.cw_fm_picture_2 && (
            <img className="shape" src={ShapeOutline} alt="Shape" />
          )}

          <img className={coach?.cw_fm_picture_2 ? "img" : 'img h-auto'} src={imgUrl} alt="Foodiary-Coaching-Bild2" />
        </C.Image>
        {/* <C.Image>
          <img className="shape" src={ShapeOutline} alt="Shape" />
          <img className="img" src={imgUrl} alt="Foodiary-Coaching-Bild2" />
        </C.Image> */}
      </C.VisualContent>
      <C.TextContent>
        <Chip text="ABLAUF" margin="0 0 4rem 0" />
        <C.Title className="ct-headline foodiary-h2 color-dark text-4xl">Dein Weg zu einem neuen Lebensgefühl</C.Title>
        <C.PlanBoxIcons className="procedure">
          <li>
            <C.PlanBoxIconBox>
              <img src={GreenTick} alt="Tick" />
            </C.PlanBoxIconBox>
            <C.PlanBoxInformation className="ct-text-block foodiary-list-item text-base color-dark text-left font-normal">
              Lerne mich in einem kostenlosem und unverbindlichem Video-Call
              kennen.
            </C.PlanBoxInformation>
          </li>
          <li>
            <C.PlanBoxIconBox>
              <img src={GreenTick} alt="Tick" />
            </C.PlanBoxIconBox>
            <C.PlanBoxInformation className="ct-text-block foodiary-list-item text-base color-dark text-left font-normal">
              Erstelle gemeinsam mit mir deinen persönlichen Ernährungsplan.
            </C.PlanBoxInformation>
          </li>
          <li>
            <C.PlanBoxIconBox>
              <img src={GreenTick} alt="Tick" />
            </C.PlanBoxIconBox>
            <C.PlanBoxInformation className="ct-text-block foodiary-list-item text-base color-dark text-left font-normal">
              Stell mir deine Fragen rund um das Thema Ernährung,
              Ernährungsplanung und Coaching. Profitiere von meinem
              Expertenwissen.
            </C.PlanBoxInformation>
          </li>
          <li>
            <C.PlanBoxIconBox>
              <img src={GreenTick} alt="Tick" />
            </C.PlanBoxIconBox>
            <C.PlanBoxInformation className="ct-text-block foodiary-list-item text-base color-dark text-left font-normal">
              Entscheide dich ganz unverbindlich, ob du deine
              Ernährungsumstellung ohne meine Hilfe beginnen möchtest oder du
              von meiner Erfahrung und dauerhaften Betreuung profitieren
              möchtest.
            </C.PlanBoxInformation>
          </li>
        </C.PlanBoxIcons>
      </C.TextContent>
    </C.Wrap>
  )
}

export default NewAttitude
