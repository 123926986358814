import React from "react";
import { useSelector } from "react-redux";
import { getS3ImageUrl } from "configs/utils";

import Chip from "components/general/formComponents/chip/Chip";

import * as C from "./standardProgram.styled";
// Images
import Coach from "assets/images/pngs/detaulf-coachingpro.jpg";
import GreenTick from "assets/images/myCoaching/Icon-check.svg";
import ShapeOutline from "assets/images/pngs/shape-outline11px.png";

const StandardProgram = () => {
  const coach = useSelector((state) => state.coach.coach);

  console.log("getS3ImageUrl(coach?.cw_cp_picture_2)", getS3ImageUrl(coach?.cw_cp_picture_2))
  const imgUrl = coach?.cw_cp_picture_2
    ? getS3ImageUrl(coach?.cw_cp_picture_2)
    : Coach;

  return (
    <C.Wrap>
      <C.VisualContent>
        <C.Image>
          {coach?.cw_cp_picture_2 && (
            <img className="shape" src={ShapeOutline} alt="Shape" />
          )}

          <img
            className={coach?.cw_cp_picture_2 ? "img" : "img h-auto"}
            src={imgUrl}
            alt="Foodiary-Coaching-Bild2"
          />
        </C.Image>
      </C.VisualContent>
      <C.TextContent>
        <Chip text="ABLAUF" margin="0 0 30px 0" />
        <C.Title className="ct-text-block font-bold color-dark text-left text-4xl">
          Persönlichkeit statt Standard-Programm
        </C.Title>
        <C.Description className="ct-text-block color-paragraph text-base text-left">
          Bei meinem Coaching PRO handelt es sich um kein vorgefertigtes
          Programm, sondern um eine 100% persönliche Ernährungsbetreuung. Du
          erhältst einen persönlichen Ernährungsplan mit über 1700 leckeren
          Rezepten und mich als festen Ansprechpartner für die kommenden 3
          Monate deiner Ernährungsumstellung.
        </C.Description>
        <C.PlanBoxIcons>
          <li>
            <C.PlanBoxIconBox>
              <img src={GreenTick} alt="Tick" />
            </C.PlanBoxIconBox>
            <C.PlanBoxInformation className="ct-text-block foodiary-list-item text-base color-dark text-left font-normal">
              Plan wird persönlich für dich erstellt.
            </C.PlanBoxInformation>
          </li>
          <li>
            <C.PlanBoxIconBox>
              <img src={GreenTick} alt="Tick" />
            </C.PlanBoxIconBox>
            <C.PlanBoxInformation className="ct-text-block foodiary-list-item text-base color-dark text-left font-normal">
              Über 1700 abwechslungsreiche Rezepte
            </C.PlanBoxInformation>
          </li>
          <li>
            <C.PlanBoxIconBox>
              <img src={GreenTick} alt="Tick" />
            </C.PlanBoxIconBox>
            <C.PlanBoxInformation className="ct-text-block foodiary-list-item text-base color-dark text-left font-normal">
              Ich stehe als dein Ernährungsberater an deiner Seite
            </C.PlanBoxInformation>
          </li>
        </C.PlanBoxIcons>
      </C.TextContent>
    </C.Wrap>
  );
};

export default StandardProgram;
